<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Books
            <v-spacer></v-spacer>
            <add-button
              class="ml-2"
              id="btn-add-staff"
              permission="library-create"
              @action="form.dialog = true,save_flag=true"
              >&nbsp;&nbsp;New Book
            </add-button>
            
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pb-0 mb-0">
              <v-flex mb-5 xs6>
                <v-text-field
                  label="Search By Book Name"
                  v-model="book_name"
                  outlined
                  dense
                  class="mt-0 mb-0"
                ></v-text-field>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td align="left">{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.name }}
                  <div>
                    <span style="font-size: 10px; color: #666" v-if="item.display_book_code"
                      >
                      ({{ item.display_book_code || ''}})
                      </span
                    >
                  </div>
                </td>
                <td class="text-xs-left">{{ item.cost.currency() }}</td>
                <td class="text-xs-left">{{ item.language }}</td>
                <!-- class="text-xs-left issue_book" -->
                <!-- <td
                  :class="
                    item.issued_book_num > 0
                      ? 'text-xs-left issue_book'
                      : 'text-xs-left'
                  "
                  @click="issuedBook(item.id, item.issued_book_num)"
                >
                  {{ item.issued_book_num }}
                </td > -->
                <td :class="
                    item.issued_book_num > 0
                      ? 'text-xs-left issue_book'
                      : 'text-xs-left'
                  ">
                  <v-btn
                  class="ma-2"
                  outlined
                  color="primary"
                  x-small
                  :disabled="item.issued_book_num > 0 ? false:true"
                  @click="issuedBook(item.id, item.issued_book_num)"

                  >
                  {{ item.issued_book_num }} Issued
                </v-btn>

                </td>
                 <!-- <td v-if="item.quantity > item.total_stock"> -->
                  <td class="text-xs-left" >
                    <v-btn
                      class="ma-2"
                      outlined
                      color="primary"
                      x-small
                      @click="viewBookActivityLog(item.id)"
                    >
                      {{ item.remaining_book_quantity }} / {{ item.quantity }}

                    </v-btn>
                    <!-- {{ item.quantity - item.total_stock }} -->
                  </td>
                <!-- </td> -->
                <td class="text-right">
                  <!-- $auth.can('library-update') && -->
                  <v-btn
                    v-if="$auth.can('library-update') && item.remaining_book_quantity > 0 && item.generated_book_code_count > 0"
                    x-small
                    outlined="outlined"
                    color="primary"
                    @click="(bookIssue = true), 
                    (book_id = item.id, book_isbn_number=item.isbn_number), 
                    compKey +=1"
                  >
                    <strong class="ml-1">Issue</strong>
                  </v-btn>
                  <edit-button
                    permission="library-update"
                    @agree="form.edit(item), (save_flag = false),allApicall(),edit_data=item,book_id = item.id, compRefreshKey = compRefreshKey + 1"
                  />
                  <delete-button
                    :onCurrentBatch="true"
                    permission="library-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="form.dialog"
        persistent
        scrollable
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn
              id="staff_form_close"
              icon
              dark
              @click="(form.dialog = false), reset(),(save_flag = true)"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Add/Update Book</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn id="save_button" dark text @click="validate()">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="pb-1" style="padding: 0">
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-timeline align-top dense>
                <v-timeline-item color="red" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>Book Info.</strong>
                      <div class="caption">Author,Category,Sector,Rack.</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm3>
                              <VLibrarySearchFieldVue 
                                label="Search Author *" 
                                searchType="author"
                                @onSelectResult="selectResult"
                                @onItemRemove="onItemRemove"
                                @onIconClick="iconClick"
                                :displayAddIcon="true"
                                :editData="edit_data"
                                :multipleSelect="true"
                                :key="compRefreshKey"
                              >
                              </VLibrarySearchFieldVue>

                              <!-- <v-select
                                @click="getAuthors()"
                                autocomplete="off"
                                :items="authors"
                                label="Author"
                                outlined
                                dense
                                multiple
                                v-model="form.author_id"
                                class="pa-0"
                                append-icon="add_circle"
                                @click:append="displayModal(4)"
                              ></v-select> -->
                            </v-flex>
                            <v-flex sm3>
                              <VLibrarySearchFieldVue 
                                label="Search Category" 
                                searchType="category"
                                @onSelectResult="selectResult"
                                @onIconClick="iconClick"
                                :displayAddIcon="false"
                                :editData="edit_data"
                                :multipleSelect="false"
                                :key="compRefreshKey"


                              >
                              </VLibrarySearchFieldVue>
                              <!-- <v-select
                                autocomplete="off"
                                :items="categories"
                                label="Category"
                                outlined
                                dense
                                v-model="form.category_id"
                                class="pa-0"
                                @click="getCategories()"

                              ></v-select> -->
                            </v-flex>
                            <v-flex sm3>
                              <v-select
                                autocomplete="off"
                                :items="sectors"
                                label="Sector"
                                outlined
                                dense
                                v-model="form.sector_id"
                                class="pa-0"
                                @click="getSector()"
                              ></v-select>
                            </v-flex>
                            <v-flex sm3>
                              <v-select
                                autocomplete="off"
                                :items="racks"
                                label="Rack"
                                outlined
                                dense
                                v-model="form.rack_id"
                                class="pa-0"
                                @click="getRack()"
                              ></v-select>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item color="red" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>Book Resource Info.</strong>
                      <div class="caption">Publisher,Vendor,Donor</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm4>
                              <VLibrarySearchFieldVue 
                                label="Search Publisher *" 
                                searchType="publisher"
                                @onSelectResult="selectResult"
                                @onIconClick="iconClick"
                                :displayAddIcon="true"
                                :editData="edit_data"
                                :key="compRefreshKey"


                              >
                              </VLibrarySearchFieldVue>
                              <!-- <v-select
                                autocomplete="off"
                                :items="publishers"
                                label="Publisher *"
                                outlined
                                dense
                                v-model="form.publisher_id"
                                class="pa-0"
                                append-icon="add_circle"
                                @click:append="displayModal(1)"
                                @click="getPublisher()"
                                name="publisher"
                                 :rules="[
                                  (v) => !!v || 'publisher is required',
                                ]"
                                :error-messages="form.errors.get('publisher')"
                              ></v-select> -->
                            </v-flex>
                            <v-flex sm4>
                              <VLibrarySearchFieldVue 
                                label="Search Vendor" 
                                searchType="vendor"
                                @onSelectResult="selectResult"
                                @onIconClick="iconClick"
                                :displayAddIcon="true"
                                :editData="edit_data"
                                :key="compRefreshKey"

                              >
                              </VLibrarySearchFieldVue>
                              <!-- <v-select
                                autocomplete="off"
                                :items="vendors"
                                label="Vendor *"
                                outlined
                                dense
                                v-model="form.vendor_id"
                                class="pa-0"
                                append-icon="add_circle"
                                name="vendor"
                                 :rules="[
                                  (v) => !!v || 'Vendor is required',
                                ]"
                                :error-messages="form.errors.get('vendor')"
                                @click:append="displayModal(2)"
                                @click="getVendor()"
                              ></v-select> -->
                            </v-flex>
                            <v-flex sm4>
                              <VLibrarySearchFieldVue 
                                label="Search Donor" 
                                searchType="donor"
                                @onSelectResult="selectResult"
                                @onIconClick="iconClick"
                                :displayAddIcon="true"
                                :editData="edit_data"
                                :key="compRefreshKey"

                              >
                              </VLibrarySearchFieldVue>
                              <!-- <v-select
                                autocomplete="off"
                                :items="donors"
                                label="Donor"
                                outlined
                                dense
                                v-model="form.donor_id"
                                class="pa-0"
                                append-icon="add_circle"
                                @click:append="displayModal(3)"
                                @click="getDonor()"
                              ></v-select> -->
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item color="red" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>Academc Info.</strong>
                      <div class="caption">Subject</div>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm4>
                              <v-select
                                autocomplete="off"
                                :items="subjects"
                                label="Subjects"
                                outlined
                                dense
                                v-model="form.subject_id"
                                class="pa-0"
                                @click="getSubject()"
                              ></v-select>
                            </v-flex>
                            <!-- <v-flex sm4>
                              <v-select
                                autocomplete="off"
                                label="Grade"
                                outlined
                                dense
                                v-model="form.grade_id"
                                class="pa-0"
                              ></v-select>
                            </v-flex> -->
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item color="red" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>General Info.</strong>
                      <!-- <div class="caption">Name,IsbnNumber</div> -->
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row>
                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Name*"
                                required
                                v-model="form.name"
                                name="name"
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                                :rules="[(v) => !!v || 'Name is required']"
                              />
                            </v-flex>
                            <v-flex sm-2 class="book_code">
                              <v-text-field
                                autocomplete="off"
                                label="Book Code *"
                                v-model="form.book_code"
                                name="book_code"
                                outlined
                                dense
                                required
                                :error-messages="form.errors.get('book_code')"
                                :rules="[(v) => !!v || 'Book Code is required']"
                              />
                              <span style="color: red;" v-if="book_code_exist_error">
                                <p>Book Code Already Exist</p>
                              </span>
                            </v-flex>
                            <v-flex sm-2>
                              <v-text-field
                                autocomplete="off"
                                label="Isbn Number"
                                v-model="form.isbn_number"
                                name="isbn_number"
                                outlined
                                dense
                              />
                            </v-flex>
                            <v-flex sm-2>
                              <v-text-field
                                autocomplete="off"
                                label="Serial Number"
                                v-model="form.serial_number"
                                name="serial_number"
                                outlined
                                dense
                               
                              />
                            </v-flex>

                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Accession Number"
                                required
                                v-model="form.accession_no"
                                name="accession_number"
                                outlined
                                dense
                              />
                            </v-flex>
                          </v-layout>
                          <v-layout row>
                            <v-flex sm-3>
                              <v-select
                                autocomplete="off"
                                :items="language"
                                label="Language"
                                outlined
                                dense
                                v-model="form.language"
                                class="pa-0"
                                width="35"
                                @click="getLanguages()"
                              ></v-select>
                            </v-flex>
                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Edition"
                                required
                                v-model="form.edition"
                                name="edition"
                                outlined
                                dense
                              />
                            </v-flex>
                            <v-flex sm-3 >
                              <v-calendar-field
                                id-val="year"
                                v-model="form.year"
                                label="Published Year"
                              >
                              </v-calendar-field>
                            </v-flex>
                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Pages"
                                required
                                v-model="form.pages"
                                name="page"
                                outlined
                                dense
                              />
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Cost*"
                                required
                                v-model="form.cost"
                                name="cost"
                                outlined
                                dense
                                :error-messages="form.errors.get('cost')"
                                :rules="[(v) => !!v || 'Cost is required']"
                              />
                            </v-flex>
                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Quantity *"
                                required
                                v-model="form.quantity"
                                name="quantity"
                                outlined
                                dense
                                :error-messages="form.errors.get('quantity')"
                                :rules="[(v) => !!v || 'Quantity is required']"
                              />
                            </v-flex>
                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Volume"
                                required
                                v-model="form.volume"
                                name="volume"
                                outlined
                                dense
                              />
                            </v-flex>
                            <v-flex sm-3>
                              <v-text-field
                                loading="true"
                                type="number"
                                autocomplete="off"
                                label="Book Number"
                                required
                                v-model="form.book_no"
                                name="book_number"
                                outlined
                                dense
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item color="red" small>
                  <v-layout wrap pt-3>
                    <v-flex>
                      <strong>Other Info.</strong>
                      <v-flex pt-3 xs11>
                        <v-container fluid style="padding: 0" grid-list-md>
                          <v-layout row>
                            <v-flex sm-3>
                              <v-text-field
                                autocomplete="off"
                                label="Bill Number"
                                required
                                v-model="form.bill_no"
                                name="bill_no"
                                outlined
                                dense
                              />
                            </v-flex>
                            <v-flex sm-3>
                              <v-calendar-field
                                id-val="bill_date"
                                v-model="form.bill_date"
                                label="Bill Date"
                              >
                              </v-calendar-field>
                            </v-flex>
                            <!-- <v-flex sm-3> -->
                            <!-- <v-text-field
                                type="number"
                                autocomplete="off"
                                label="Total StocK"
                                required
                                v-model="form.total_stock"
                                name="total_stock"
                                outlined
                                dense
                              /> -->
                            <!-- </v-flex> -->
                          </v-layout>
                          <v-layout row>
                            <v-flex sm-6>
                              <v-textarea
                                label="Description"
                                no-resize
                                rows="3"
                                v-model="form.description"
                                row-height="15"
                                outlined
                              ></v-textarea>
                            </v-flex>
                            <v-flex sm-6>
                              <v-textarea
                                label="Remarks"
                                no-resize
                                rows="3"
                                v-model="form.remarks"
                                row-height="15"
                                outlined
                              ></v-textarea>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-timeline-item>
              </v-timeline>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showModal" persistent max-width="400px">
        <publisher
          v-if="show_publisher"
          :reusecomponent="reusecomponent"
          :key="key"
          v-on:getPublisher="getPublisher()"
          v-on:reset="reset()"
        ></publisher>
        <vendor
          v-if="show_vendor"
          :reusecomponent="reusecomponent"
          :key="key"
          v-on:getVendor="getVendor()"
          v-on:reset="reset()"
        >
        </vendor>
        <donor
          v-if="show_donor"
          :reusecomponent="reusecomponent"
          :key="key"
          v-on:getDonor="getDonor()"
          v-on:reset="reset()"
        ></donor>
        <author
          v-if="show_author"
          :reusecomponent="reusecomponent"
          :key="key"
          v-on:getAuthors="getAuthors()"
          v-on:reset="reset()"
        ></author>
      </v-dialog>

      <!-- <v-dialog v-model="bookIssue" persistent max-width="400px"> -->
        <!-- {{ book_id }} -->
        <BookIssue
          v-if="bookIssue"
          :key="compKey"
          v-on:resetBookIssue="resetBookIssue()"
          :book_id="book_id"
          :book_isbn_number="book_isbn_number"
          :reusecomponent="true"
        ></BookIssue>
      <!-- </v-dialog> -->
    </v-layout>
  </v-container>
</template>
<script>
import VLibrarySearchFieldVue from "../../../../components/app/VLibrarySearchField.vue";
import publisher from "../Publisher/index.vue";
import vendor from "../Vendors/index.vue";
import donor from "../Donor/index.vue";
import author from "../Authors/index.vue";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import bookFilter from "@/library/BookFilter";
import BookIssue from "./BookIssue.vue";
import languagesData from "@/assets/json/languages.json";
let typingTimer;
let doneTypingInterval = 400;
export default {
  mixins: [Mixins,bookFilter],
  components: { publisher, vendor, donor, author, BookIssue, VLibrarySearchFieldVue },
  data: () => ({
    compRefreshKey:0,
    key: 0,
    compKey :0,
    book_name: "",
    bookIssue: false,
    book_id: "",
    book_isbn_number:"",
    showModal: false,
    reusecomponent: false,
    
    // show_publisher: false,
    // show_vendor: false,
    // show_donor: false,
    show_author: false,

    save_flag: true,
    lazy: false,
    valid: true,
    // authors: [],
    // donors: [],
    // vendors: [],
    // publishers: [],

    // racks: [],
    // sectors: [],
    // subjects: [],
    // categories: [],
    // languagesData,
    // language: [],
    form: new Form(
      {
        dialog: false,
        author_id: [],
        vendor_id: "",
        donor_id: "",
        publisher_id: "",
        rack_id: "",
        sector_id: "",
        grade_id: "",
        subject_id: "",
        category_id: "",
        name: "",
        isbn_number: "",
        cost: "",
        serial_number: "",
        language: "",
        edition: "",
        description: "",
        total_stock: "",
        accession_no: "",
        pages: "",
        volume: "",
        bill_no: "",
        bill_date: "",
        book_no: "",
        quantity: "",
        remarks: "",
        year: "",
        barcode: "",
        book_code:"",
      },
      "library/api/book"
    ),
    headers: [
      { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Cost", align: "left", value: "cost", sortable: false },
      { text: "Language", align: "left", value: "language", sortable: false },
      {
        text: "No. Book Issued",
        align: "left",
        value: "issued_book_num",
        sortable: false,
      },
      {
        text: "No. of Available / Total Book",
        align: "left",
        value: "available_book",
        sortable: false,
      },
      { text: "Action", align: "right", sortable: false },
    ],
    edit_data:'',
    book_code_exist_error:false,
   
  }),
  watch: {
    'form.book_code':function(val){
      if (!val) return;
      if (val.length < 1) return;
      const noSpecialChars = val.replace(/[^a-z0-9]/gi, '');
      let $this = this;
      clearTimeout(typingTimer);
        typingTimer = setTimeout(function () {
          $this.checkBookCode(noSpecialChars)
      }, doneTypingInterval);
    },
    pagination: function () {
      this.get();
    },
    book_name(val) {
      if (!val) return this.get();

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function () {
        $this.get();
      }, doneTypingInterval);
    },
   
  },
  mounted() {

    // if(!this.save_flag){
      // this.getAuthors()
      // this.getDonor()
      // this.getVendor()
      // this.getPublisher()
      // this.getRack()
      // this.getSector()
      // this.getCategories()
      // this.getSubject()
    // }
  },
  methods: {
    // validateSpecialChar(e){
    //   let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

    // },
    checkBookCode(code){
      this.book_code_exist_error = false;
      this.form.book_code = code;
      this.$rest.get('/library/api/check-book-code?search='+code+"&book_id="+this.book_id)
      .then((res)=>{
        this.book_code_exist_error = res.data
      })
    },
    selectResult(detail,searchType){
      if(searchType === 'author'){
        if(detail) {
          this.form.author_id.push(detail.id)
        } else {
          this.form.author_id = [];  
        }
      }
      // if(searchType === 'author') this.form.author_id = detail.id
      
      if(searchType === 'category') this.form.category_id = detail && detail.id
      if(searchType === 'publisher') this.form.publisher_id = detail && detail.id
      if(searchType === 'vendor') this.form.vendor_id = detail && detail.id
      if(searchType === 'donor') this.form.donor_id = detail && detail.id
      

    },
    onItemRemove(detail,searchType){
      this.form.author_id = [];
      if(searchType === 'author'){
        if(detail) {
          this.form.author_id.push(detail.id)
        } else {
          this.form.author_id = [];  
        }
      }
     
    },
    iconClick(searchType){
      this.reusecomponent = true;
      this.showModal = true;
      if(searchType === 'author') this.show_author = true;
      if (searchType === 'publisher') this.show_publisher = true;
      if (searchType === 'vendor') this.show_vendor = true;
      if (searchType === 'donor') this.show_donor = true;
      
    },
   
    allApicall(){
      this.getAuthors()
      // this.getDonor()
      // this.getVendor()
      // this.getPublisher()
      this.getRack()
      this.getSector()
      this.getCategories()
      this.getSubject()
    },
    issuedBook(id, issued_book_num) {
      if (issued_book_num > 0)
        this.$router.push("/books_issue?" + "&book_id=" + id);
    },
    viewBookActivityLog(id){
      // this.router.push({ path: "/book-log?" + "&book_id=" , query: { bookName: book_name }})

      this.$router.push("/book-log?" + "&book_id=" + id);

    },
    // getLanguages() {
    //   this.languagesData.forEach((obj) => {
    //     this.language.push(obj.name);
    //   });
    // },
    get(params) {
      let extraparams = "&search_query=" + this.book_name;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    // getAuthors() {
    //   this.$rest.get("/library/api/author").then(({ data }) => {
    //     var authors_data = data.data;
    //     this.authors = authors_data.map((author) => {
    //       return { text: author.name, value: author.id };
    //     });
    //   });
    //   this.show_author = false;
    //   this.reusecomponent = false;
    //   this.showModal = false;
    // },
    // getDonor() {
    //   this.donors = [];
    //   this.$rest.get("/library/api/donor").then(({ data }) => {
    //     var donors_data = data.data;
    //     this.donors = donors_data.map((donor) => {
    //       return { text: donor.name, value: donor.id };
    //     });
    //   });
    //   this.show_donor = false;
    //   this.reusecomponent = false;
    //   this.showModal = false;
    // },
    // getVendor() {
    //   this.$rest.get("/library/api/vendor").then(({ data }) => {
    //     var vendor_data = data.data;
    //     this.vendors = vendor_data.map((vendor) => {
    //       return { text: vendor.name, value: vendor.id };
    //     });
    //   });
    //   this.show_vendor = false;
    //   this.reusecomponent = false;
    //   this.showModal = false;
    // },
    // getPublisher() {
    //   this.$rest.get("/library/api/publisher").then(({ data }) => {
    //     var publisher_data = data.data;
    //     this.publishers = publisher_data.map((publisher) => {
    //       return { text: publisher.name, value: publisher.id };
    //     });
    //   });
    //   this.show_publisher = false;
    //   this.reusecomponent = false;
    //   this.showModal = false;
    // },
    // getRack() {
    //   this.$rest.get("/library/api/rack").then(({ data }) => {
    //     var rack_data = data.data;
    //     this.racks = rack_data.map((rack) => {
    //       return { text: rack.name, value: rack.id };
    //     });
    //   });
    // },
    // getSector() {
    //   this.$rest.get("/library/api/sector").then(({ data }) => {
    //     var sector_data = data.data;
    //     this.sectors = sector_data.map((sector) => {
    //       return { text: sector.name, value: sector.id };
    //     });
    //   });
    // },
    // getCategories() {
    //   this.$rest.get("/library/api/category").then(({ data }) => {
    //     var category_data = data.data;
    //     this.categories = category_data.map((category) => {
    //       return { text: category.name, value: category.id };
    //     });
    //   });
    // },
    // getSubject() {
    //   this.$rest.get("/library/api/subject").then(({ data }) => {
    //     var subject_data = data.data;
    //     this.subjects = subject_data.map((subject) => {
    //       return { text: subject.name, value: subject.id };
    //     });
    //   });
    // },
    validate() {
      if (this.$refs.form.validate()) {
        this.form.store().then((res) => {
          // this.$form.reset();
          this.compRefreshKey = this.compRefreshKey + 1;
          this.get()

        });
      }
    },
    displayModal(status) {
      this.reusecomponent = true;
      this.showModal = true;
      if (status === 1) {
        this.show_publisher = true;
      }
      if (status === 2) {
        this.show_vendor = true;
      }
      if (status === 3) {
        this.show_donor = true;
      }
      if (status === 4) {
        this.show_author = true;
      }
      this.key += 1;
    },
    reset() {
      console.log("here", this.form.author_id);
      this.showModal = false;
      this.reusecomponent = false;
      this.show_publisher = false;
      this.show_vendor = false;
      this.show_donor = false;
      this.form.reset();
      this.form.author_id = [];
      this.form.author_name = [];
      this.form.category_id = "";
      this.form.category_name = "";
      this.form.display_book_code = "";
      this.form.donor_name = "";
      this.form.donor_id = "";

      console.log(this.form)
    },
    // formReset(){

    // },
    resetBookIssue() {
      this.get();
      this.bookIssue = false;
      
    },
    
  },
};
</script>
<style lang="scss">
.issue_book:hover {
  cursor: pointer;
}

</style>